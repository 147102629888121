<template>
  <div class="not-found">
    <div class="container">
      <div class="not-found-content">
        <b-img
          :src="`${publicPath}assets/images/broken-link.svg`"
          alt=""
        ></b-img>
        <h1>{{ $t("404Page.oops") }}</h1>
        <h3>{{ $t("404Page.error404") }}</h3>
        <p>{{ $t("404Page.sorryFriend") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t("meta.404.title"),
      titleTemplate: null
    };
  }
};
</script>
